/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: MapOverlay Configuration component
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ClickAwayListener, Fade, Switch} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LabelIcon from '@mui/icons-material/Label';
import polylabel from 'polylabel';
import {OverlayView} from '@react-google-maps/api';
import {isArray, groupBy, orderBy, isNil, forEach} from 'lodash';

import {
  MAP_ELID_SECTION,
  MAP_ELID_CONTROLS_SUBSECTION,
  NetworkCapability,
  KA_REGIONAL_PLAN_SERVICE_COVERAGE,
  KU_REGIONAL_PLAN_SERVICE_COVERAGE
} from '../../../utils/constants';
import kaBeamOverlayQuery from '../../../store/queries/beamOverlay/kaSatelliteBeams';
import kuBeamOverlayQuery from '../../../store/queries/beamOverlay/kuSatelliteBeams';
import txInhibitZonesQuery, {txInhibitZoneData} from '../../../store/queries/common/txInhibitZonesQuery';
import kaSatellitesQuery from '../../../store/queries/common/kaSatellites';
import kuSatellitesQuery from '../../../store/queries/common/kuSatellites';
import {FleetMapAction} from '../../../store/reducers/FleetMapReducer';
import {useStore} from '../../../store/Store';
import {getElementId} from '../../../utils/ElementIdUtils';
import {MapViewContext} from '../../../utils/MapUtil';
import useFetch from '../../../utils/useFetch';
import HelpToolTip from '../../common/elements/tooltip/HelpTooltip';
import StyledButton from '../../common/StyledButton';
import {Span} from '../aircraftPopups/AircraftPopupStyles';
import {
  ESatBeamEventType,
  ESatBeamImpactType,
  getImpactEventStyle,
  getKaOverlays,
  getKuOverlays,
  getServicePlanCoverage,
  handleBeamOverlayClose,
  handleBeamOverlayImpactIncident,
  ISelectedSatBeam,
  loadSelectedSatBeamFromStore
} from '../settings/MapHelper';
import {
  CoverageMapContainer,
  CoverageMapOuterContainer,
  CoverageMapTitle,
  CoveragePopupDiv,
  CustomSwitchContainer,
  ExpandContainer,
  HelpWrap,
  KuSatelliteRegionGroup,
  MapOverlayButtonWrapper,
  MapOverlayConfigurationsProps,
  ScrollContainer,
  TailTagConfigurationContainer,
  TailTagLargeSpan,
  TailTagSizeHoverStyle,
  TailTagSmallSpan
} from './MapOverlayConfigurationStyles';
import kaConnectedSatelliteBeamOverlayQuery from '../../../store/queries/beamOverlay/kaConnectedSatelliteBeams';
import KuConnectedSatelliteBeamsQuery from '../../../store/queries/beamOverlay/kuConnectedSatelliteBeams';
import SelectedSatBeamPopup, {
  convertSatBeamData,
  ESatBeamProps,
  initialSatBeamData,
  ISatBeamData,
  SAT_BEAM_POPUP_OFFSET_X,
  SAT_BEAM_POPUP_OFFSET_Y
} from './SelectedSatBeamPopup';
import styled from '@emotion/styled';

interface IBeamMarkerWithLabel {
  markerPosition: {lat: number; lng: number};
  id: string;
  label: string;
  secondaryLabel: string;
}

const BeamMarkerWithLabel: React.FC<{beamMarker: IBeamMarkerWithLabel}> = ({beamMarker}) => {
  const BeamMarkerContainer = styled.div`
    font-family: 'Source Sans Pro',sans-serif;
    font-size: 12px;
    width: 60px;
    text-align: center;
    font-weight: 700;
    line-height: 12px;
    text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;: 5px;
    position: relative;
    z-index: 31;
    display: inline-block;
    color: black;
  }
`;
  const beamId = `mapOverlay__beam--${beamMarker.id}`;
  return (
    <>
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_LAYER}
        position={beamMarker.markerPosition}
        getPixelPositionOffset={(x, y) => ({x: -28, y: -14})}
      >
        <>
          <BeamMarkerContainer id={beamId}>
            <span className="satellite">{beamMarker.label}</span>
            <br />
            {beamMarker.secondaryLabel && beamMarker.secondaryLabel}
          </BeamMarkerContainer>
        </>
      </OverlayView>
    </>
  );
};

const MapOverlayConfigurations: React.FC<MapOverlayConfigurationsProps> = ({
  map,
  showMapOverlayPopup,
  closeOverlayPopup,
  mapHeight,
  searchBoxDisabled,
  setTailTagOpen,
  isTailTagOpen,
  setShowLargePopup,
  showLargePopup,
  disableTailTagConfig,
  mapOverlayContext,
  selectedAircraftData,
  selectedAircraftNetwork
}) => {
  // This will return Tail Tag hover class
  const {classes} = TailTagSizeHoverStyle();

  const [showKa, setShowKa] = useState(true);
  const [showKu, setShowKu] = useState(true);
  const [showTagConfiguration, setShowTagConfiguration] = useState(true);
  const [showTxInhibitZones, setShowTxInhibitZones] = useState(true);

  const [beamMarkers, setBeamMarkers] = useState<IBeamMarkerWithLabel[]>(null);
  const [kaConnectedBeamMarkers, setKaConnectedBeamMarkers] = useState<IBeamMarkerWithLabel[]>(null);
  const [kuBeamMarkers, setKuBeamMarkers] = useState<IBeamMarkerWithLabel[]>(null);
  const [kuConnectedBeamMarkers, setKuConnectedBeamMarkers] = useState<IBeamMarkerWithLabel[]>(null);
  const {store, dispatch} = useStore();
  const {overlays} = store.fleetMap;
  const {satelliteBeamIncidentEvents} = overlays;

  const {
    init: {viewAsCustomer, isInternal}
  } = store;
  const groupCode = store.customer.current.code;
  const scrollContainerHeight =
    [MapViewContext.FLIGHT_DETAILS_MAP, MapViewContext.CONNECTIVITY_OUTLOOK_MAP].indexOf(mapOverlayContext) > -1
      ? 150
      : 250;
  const {data: kaSatellites} = useFetch(
    kaSatellitesQuery,
    useMemo(() => {
      if (!isInternal) return null;
      return {};
    }, [isInternal])
  );
  const {data: kuSatellites} = useFetch(
    kuSatellitesQuery,
    useMemo(() => ({}), [])
  );
  const {data: txInhibitGeoJSON, isLoading: isTxInhibitZoneLoading} = useFetch<txInhibitZoneData>(
    txInhibitZonesQuery,
    useMemo(() => {
      if (!isInternal) return null;
      return {};
    }, [isInternal])
  );

  const kaOverlayChecked = overlays.showKaOverlay === 'true' ? true : false;
  const kuOverlayChecked = overlays.showKuOverlay === 'true' ? true : false;
  const txInhibitZonesCheckedBelow10kFeet = overlays.showTxInhibitZoneBelow10kFt === 'true' ? true : false;
  const txInhibitZonesCheckedAtAllAltitudes = overlays.showTxInhibitZoneAllAltitudes === 'true' ? true : false;
  const connectedBeamsOverlayChecked = overlays.showConnectedBeams === 'true' ? true : false;
  const isActiveAircraftConnectedBeams = connectedBeamsOverlayChecked && Boolean(selectedAircraftData?.aircraftId);
  const selectedSatelliteDetails = loadSelectedSatBeamFromStore(store);
  const selectedSatBeamRef = useRef<ISelectedSatBeam | undefined>(undefined);
  selectedSatBeamRef.current = selectedSatelliteDetails;

  const beamParams = useMemo(() => {
    if (!isInternal || overlays.selectedKaSatellites.length === 0) {
      return null;
    }

    return {
      satelliteIds: overlays.selectedKaSatellites,
      flightId: isActiveAircraftConnectedBeams ? selectedAircraftData?.aircraftId : undefined
    };
  }, [isInternal, overlays.selectedKaSatellites, isActiveAircraftConnectedBeams, selectedAircraftData]);

  const kuBeamParams = useMemo(() => {
    if (overlays.selectedKuSatellites.length === 0) {
      return null;
    }

    return {
      networkRegionCodes: overlays.selectedKuSatellites
    };
  }, [overlays.selectedKuSatellites]);

  const [beamCache, setBeamCache] = useState({beams: null, cacheId: ''});
  const [kuBeamCache, setKuBeamCache] = useState({beams: null, cacheId: ''});
  const [kuConnectedBeamCache, setKuConnectedBeamCache] = useState({beams: null, cacheId: ''});

  const {data: satelliteBeams} = useFetch(kaBeamOverlayQuery, beamParams);
  const {data: kuSatelliteBeams} = useFetch(kuBeamOverlayQuery, kuBeamParams);

  const cacheId = useMemo(() => [...overlays.selectedKaSatellites].sort().join('-'), [overlays.selectedKaSatellites]);

  const kuCacheId = useMemo(() => [...overlays.selectedKuSatellites].sort().join('-'), [overlays.selectedKuSatellites]);

  const displaySatellites: boolean = useMemo(
    () => overlays.selectedKaSatellites.length > 0,
    [overlays.selectedKaSatellites.length]
  );

  const displayConnectedSatellites: boolean = useMemo(
    () => isActiveAircraftConnectedBeams,
    [isActiveAircraftConnectedBeams]
  );
  const connectedCacheId = useMemo(() => 'c-' + selectedAircraftData?.aircraftId, [selectedAircraftData]);
  const [connectedBeamCache, setConnectedBeamCache] = useState({beams: null, cacheId: ''});

  const kaConnectedBeamQueryParams = useMemo(() => {
    if (!selectedAircraftData || !isInternal || !connectedBeamsOverlayChecked) {
      return null;
    }
    return {
      aircraftId: selectedAircraftData?.aircraftId,
      groupCode,
      startTstamp: selectedAircraftData?.connectedStartTimestamp,
      endTstamp: selectedAircraftData?.connectedEndTimestamp
    };
  }, [groupCode, selectedAircraftData, isInternal, connectedBeamsOverlayChecked]);
  const {data: kaConnectedBeams} = useFetch(kaConnectedSatelliteBeamOverlayQuery, kaConnectedBeamQueryParams);

  const kuConnectedBeamQueryParams = useMemo(() => {
    if (!selectedAircraftData || !connectedBeamsOverlayChecked) {
      return null;
    }
    return {
      aircraftId: selectedAircraftData?.aircraftId,
      groupCode,
      startTstamp: selectedAircraftData?.connectedStartTimestamp,
      endTstamp: selectedAircraftData?.connectedEndTimestamp
    };
  }, [groupCode, selectedAircraftData, connectedBeamsOverlayChecked]);

  const {data: kuConnectedSatellites} = useFetch(KuConnectedSatelliteBeamsQuery, kuConnectedBeamQueryParams);
  const displayKuSatellites: boolean = useMemo(
    () => overlays.selectedKuSatellites.length > 0,
    [overlays.selectedKuSatellites]
  );

  useEffect(() => {
    if (!satelliteBeams) return;
    setBeamCache({beams: satelliteBeams, cacheId: cacheId});
    // eslint-disable-next-line
  }, [satelliteBeams]);

  useEffect(() => {
    if (!kaConnectedBeams) return;
    setConnectedBeamCache({beams: kaConnectedBeams, cacheId: connectedCacheId});
    // eslint-disable-next-line
  }, [kaConnectedBeams]);

  useEffect(() => {
    if (!kuSatelliteBeams) return;
    setKuBeamCache({beams: kuSatelliteBeams, cacheId: kuCacheId});
    // eslint-disable-next-line
  }, [kuSatelliteBeams]);

  useEffect(() => {
    if (!kuConnectedSatellites) return;
    setKuConnectedBeamCache({beams: kuConnectedSatellites, cacheId: connectedCacheId});
    // eslint-disable-next-line
  }, [kuConnectedSatellites]);

  /**
   * Removes beam markers from the map
   */
  const cleanBeamMarkers = (network: string) => {
    switch (network) {
      case 'KA':
        setBeamMarkers(null);
        break;
      case 'KU':
        setKuBeamMarkers(null);
        break;
      case 'KA-Connected':
        setKaConnectedBeamMarkers(null);
        break;
      case 'KU-Connected':
        setKuConnectedBeamMarkers(null);
        break;
      default:
        break;
    }
  };

  const renderExpand = (open: boolean, setState: (o: boolean) => void) => (
    <ExpandContainer>
      {open ? <ExpandLess onClick={() => setState(false)} /> : <ExpandMore onClick={() => setState(true)} />}
    </ExpandContainer>
  );

  /**
   * Sets if we want to show the KA overlay
   * @param checked true/false
   */
  const updateKaOverlaySettings = useCallback(
    (checked: boolean) => {
      dispatch({type: FleetMapAction.SET_SHOW_KA_OVERLAY, payload: checked.toString()});
    },
    [dispatch]
  );

  /**
   * Sets Ka Regional Service Plan Coverage
   * @param checked True or False
   * @param region Selected Region to show the coverage
   */
  const updateKaRegionalOverlaySettings = useCallback(
    (checked: boolean, region: string) => {
      dispatch({type: FleetMapAction.SET_SHOW_KA_REGIONAL_OVERLAY, payload: {checked, region}});
    },
    [dispatch]
  );

  /**
   * Sets Ku Regional Service Plan Coverage
   * @param checked True or False
   * @param region Selected Region to show the coverage
   */
  const updateKuRegionalOverlaySettings = useCallback(
    (checked: boolean, region: string) => {
      dispatch({type: FleetMapAction.SET_SHOW_KU_REGIONAL_OVERLAY, payload: {checked, region}});
    },
    [dispatch]
  );

  /**
   * Sets if we want to show the KA overlay
   * @param checked true/false
   */
  const updateKuOverlaySettings = useCallback(
    (checked: boolean) => {
      dispatch({type: FleetMapAction.SET_SHOW_KU_OVERLAY, payload: checked.toString()});
    },
    [dispatch]
  );

  /**
   * Sets if we want to show a satellite beam map
   * @param checked true/false
   * @param satelliteId Satellite ID
   */
  const updateKaSatelliteOverlaySettings = useCallback(
    (checked: boolean, satelliteId: number) => {
      dispatch({type: FleetMapAction.SET_KA_SATELLITES, payload: {checked, satelliteId}});
    },
    [dispatch]
  );
  /**
   * Sets if we want to show a satellite beam map
   * @param checked true/false
   * @param satelliteId Satellite ID
   */
  const updateKuSatelliteOverlaySettings = useCallback(
    (checked: boolean, satelliteId: string) => {
      dispatch({type: FleetMapAction.SET_KU_SATELLITES, payload: {checked, satelliteId}});
    },
    [dispatch]
  );

  /**
   * Sets if we want to show the connected beams overlay
   * @param checked true/false
   */
  const updateShowConnectedOverlaySettings = useCallback(
    (checked: boolean) => {
      dispatch({type: FleetMapAction.SET_CONNECTED_BEAMS, payload: checked.toString()});
    },
    [dispatch]
  );

  /**
   * Sets if we want to show the ATI Zones
   * @param checked true/false
   */
  const updateTxInhibitZonesSettings = useCallback(
    (checked: boolean, isBelow10kFeet: boolean) => {
      dispatch({
        type: isBelow10kFeet
          ? FleetMapAction.SET_TX_INHIBIT_ZONE_BELOW_10K_FEET
          : FleetMapAction.SET_TX_INHIBIT_ZONE_AT_ALL_ALTITUDES,
        payload: checked.toString()
      });
    },
    [dispatch]
  );

  const showHideAll = (show: boolean) => {
    updateKaOverlaySettings(show);
    updateKuOverlaySettings(show);
    updateTxInhibitZonesSettings(show, false);
    updateTxInhibitZonesSettings(show, true);
    forEach(KA_REGIONAL_PLAN_SERVICE_COVERAGE, (regionDetail) => {
      updateKaRegionalOverlaySettings(show, regionDetail.region);
    });
    forEach(KU_REGIONAL_PLAN_SERVICE_COVERAGE, (regionDetail) => {
      updateKuRegionalOverlaySettings(show, regionDetail.region);
    });
    (kaSatellites || []).forEach(({satelliteId}) => updateKaSatelliteOverlaySettings(show, satelliteId));
    (kuSatellites || []).forEach(({networkRegionCode}) => updateKuSatelliteOverlaySettings(show, networkRegionCode));
    if (!show) {
      updateShowConnectedOverlaySettings(show);
      //TODO: This is the workaround to clean up ku connected beams while clicking hide all
      cleanUpFeatures('ku-connected');
    }
  };
  const totalChecked = useMemo((): number => {
    let cnt = 0;
    cnt += Number(kaOverlayChecked);
    cnt += overlays.selectedKuSatellites.length;
    cnt += Number(kuOverlayChecked);
    cnt += Number(txInhibitZonesCheckedBelow10kFeet);
    cnt += Number(txInhibitZonesCheckedAtAllAltitudes);
    if (isInternal && !viewAsCustomer) {
      cnt += overlays.selectedKaSatellites.length;
      cnt += overlays.selectedKuSatellites.length;
      cnt += overlays?.kaRegionalServiceCoverage?.length;
      cnt += overlays?.kuRegionalServiceCoverage?.length;
    }
    return cnt;
  }, [
    kaOverlayChecked,
    kuOverlayChecked,
    isInternal,
    viewAsCustomer,
    overlays.selectedKaSatellites.length,
    overlays.selectedKuSatellites.length,
    overlays.kaRegionalServiceCoverage?.length,
    overlays.kuRegionalServiceCoverage?.length,
    txInhibitZonesCheckedBelow10kFeet,
    txInhibitZonesCheckedAtAllAltitudes
  ]);
  const totalChecks = useMemo(() => {
    let totalOverlaysAvailable = 0;
    totalOverlaysAvailable += 1; // For Overall Coverage
    totalOverlaysAvailable += (kaSatellites || []).length + (kuSatellites || []).length;
    if (isInternal && !viewAsCustomer) {
      // 3 for Ka regional service coverages, 1 for Ku regional service coverage, 2 for Tx-Inhibit Coverage
      totalOverlaysAvailable += 6;
    }
    return totalOverlaysAvailable;
  }, [kaSatellites, kuSatellites, isInternal, viewAsCustomer]);
  const [kaSatelliteGroup, setKaSatelliteGroup] = useState({});

  useEffect(() => {
    if (!kaSatellites || !kaSatellites.length) return;
    const groupedSatellites = groupBy(kaSatellites, 'region');
    setKaSatelliteGroup(groupedSatellites);
  }, [kaSatellites]);

  const kuSatelliteGroups = (kuSatellites || []).reduce((memo: KuSatelliteRegionGroup[], kuSatellite) => {
    const newEntry = {
      name: kuSatellite.satelliteName,
      id: kuSatellite.networkRegionCode
    };
    const newHead: KuSatelliteRegionGroup = {region: kuSatellite.regionName, kuSatellites: [newEntry]};
    if (memo.length === 0) {
      return [newHead];
    }
    const idx = memo.findIndex((s) => s.region === kuSatellite.regionName);
    if (idx === -1) {
      return [...memo, newHead];
    }
    memo[idx].kuSatellites.push(newEntry);
    return orderBy(memo, ['region'], ['desc']);
  }, []);

  /**
   * Cleans up google map data features for a given network type.
   * @param network Network type - ka or ku
   */
  const cleanUpFeatures = useCallback(
    (network: string) => {
      map.data.forEach((feature) => {
        if (feature.getProperty('network') === network) {
          map.data.remove(feature);
        }
      });
    },
    [map]
  );
  useEffect(() => {
    // Clean up before we do anything
    cleanUpFeatures('ka-regional');
    if (!overlays.kaRegionalServiceCoverage?.length || !map) return;
    overlays.kaRegionalServiceCoverage.map((region) => {
      const servicePlanCoverage = getServicePlanCoverage(region);
      return map.data.addGeoJson(servicePlanCoverage);
    });
  }, [map, overlays?.kaRegionalServiceCoverage, overlays?.kaRegionalServiceCoverage?.length, cleanUpFeatures]);

  useEffect(() => {
    // Clean up before we do anything
    cleanUpFeatures('ku-regional');
    if (!overlays.kuRegionalServiceCoverage?.length || !map) return;
    overlays.kuRegionalServiceCoverage.map((region) => {
      const servicePlanCoverage = getServicePlanCoverage(region);
      return map.data.addGeoJson(servicePlanCoverage);
    });
  }, [map, overlays?.kuRegionalServiceCoverage, overlays?.kuRegionalServiceCoverage?.length, cleanUpFeatures]);

  useEffect(() => {
    if (!map) return;

    // Clean up before we do anything
    cleanUpFeatures('ku');

    if (kuOverlayChecked) {
      const overlayList = getKuOverlays();

      map.data.addGeoJson({
        type: 'FeatureCollection',
        features: overlayList.map((json: any) => {
          return {
            type: 'Feature',
            properties: {network: 'ku'},
            geometry: {
              ...json
            }
          };
        })
      });
    }
  }, [kuOverlayChecked, map, updateKuOverlaySettings, cleanUpFeatures]);

  useEffect(() => {
    if (!map) return;

    // Clean up before we do anything
    cleanUpFeatures('ka');

    if (kaOverlayChecked) {
      const kaCoverageOverlay = getKaOverlays();

      map.data.addGeoJson(kaCoverageOverlay);
    }
  }, [kaOverlayChecked, map, cleanUpFeatures]);

  useEffect(() => {
    if (!map || isTxInhibitZoneLoading) return;

    // Clean up before we do anything
    cleanUpFeatures('txInhibit');
    let txInhibitZones = !isNil(txInhibitGeoJSON) ? txInhibitGeoJSON.features : [];
    if (txInhibitZonesCheckedBelow10kFeet && txInhibitZonesCheckedAtAllAltitudes) {
      txInhibitZones = !isNil(txInhibitGeoJSON) ? txInhibitGeoJSON.features : [];
    } else if (!txInhibitZonesCheckedBelow10kFeet && txInhibitZonesCheckedAtAllAltitudes) {
      txInhibitZones = txInhibitGeoJSON.features.filter((ati) => ati.properties.auth.includes('No Auth'));
    } else if (txInhibitZonesCheckedBelow10kFeet && !txInhibitZonesCheckedAtAllAltitudes) {
      txInhibitZones = txInhibitGeoJSON.features.filter((ati) => ati.properties.auth.includes('10k'));
    } else {
      cleanUpFeatures('txInhibit');
    }

    if ((txInhibitZonesCheckedBelow10kFeet || txInhibitZonesCheckedAtAllAltitudes) && !isTxInhibitZoneLoading) {
      map.data.addGeoJson({
        type: 'FeatureCollection',
        features: txInhibitZones
      });
    }
  }, [
    txInhibitZonesCheckedBelow10kFeet,
    txInhibitZonesCheckedAtAllAltitudes,
    map,
    cleanUpFeatures,
    isTxInhibitZoneLoading,
    txInhibitGeoJSON
  ]);

  useEffect(() => {
    if (!map) return;

    // Clean up before we do anything
    cleanBeamMarkers('KA-Connected');
    cleanUpFeatures('internal-connected');

    if (!selectedAircraftData?.aircraftId || mapOverlayContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP) {
      dispatch({type: FleetMapAction.SET_CONNECTED_BEAMS, payload: 'false'});
    }
    if (
      !displayConnectedSatellites ||
      viewAsCustomer ||
      !selectedAircraftData?.aircraftId ||
      connectedCacheId !== connectedBeamCache.cacheId ||
      mapOverlayContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP ||
      selectedAircraftNetwork === NetworkCapability.KU ||
      isNil(connectedBeamCache?.beams)
    )
      return;
    
    for (const feature of connectedBeamCache.beams.features) {
      // update beam incident data
      if (satelliteBeamIncidentEvents && feature.properties) {
        handleBeamOverlayImpactIncident(map, satelliteBeamIncidentEvents, feature, selectedSatBeamRef, true);
      }
    }

    const kaConnectedBeamMarkers = [];
    map.data.addGeoJson(connectedBeamCache.beams);

    for (const feature of connectedBeamCache.beams['features']) {
      const poly = polylabel(feature.geometry.coordinates, 1.0);
      kaConnectedBeamMarkers.push({
        markerPosition: {lat: poly[1], lng: poly[0]},
        id: `${feature.properties.abbreviation}-${feature.properties.beamId}`,
        secondaryLabel: feature.properties.beamId,
        label: feature.properties.abbreviation
      });

      if (satelliteBeamIncidentEvents && feature.properties) {
        handleBeamOverlayImpactIncident(map, satelliteBeamIncidentEvents, feature, selectedSatBeamRef, false);
      }
    }
    setKaConnectedBeamMarkers(kaConnectedBeamMarkers);
    // eslint-disable-next-line
  }, [connectedBeamCache, viewAsCustomer, displayConnectedSatellites, selectedAircraftNetwork]);

  useEffect(() => {
    if (!map) return;

    // Clean up before we do anything
    cleanBeamMarkers('KU-Connected');
    cleanUpFeatures('ku-connected');

    if (!selectedAircraftData?.aircraftId || mapOverlayContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP) {
      dispatch({type: FleetMapAction.SET_CONNECTED_BEAMS, payload: 'false'});
    }
    if (
      !displayConnectedSatellites ||
      !selectedAircraftData?.aircraftId ||
      connectedCacheId !== kuConnectedBeamCache.cacheId ||
      mapOverlayContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP ||
      selectedAircraftNetwork === NetworkCapability.KA
    )
      return;

    map.data.addGeoJson(kuConnectedBeamCache.beams);

    const kuConnectedBeamMarkers = [];
    for (const feature of kuConnectedBeamCache.beams['features']) {
      const poly = polylabel(feature.geometry.coordinates, 1.0);
      kuConnectedBeamMarkers.push({
        markerPosition: {lat: poly[1], lng: poly[0]},
        id: `${feature.properties.satelliteName}-${feature.properties.description}`,
        label: feature.properties.satelliteRegionName
      });
    }
    setKuConnectedBeamMarkers(kuConnectedBeamMarkers);
    // eslint-disable-next-line
  }, [kuConnectedBeamCache, displayConnectedSatellites, selectedAircraftNetwork]);

  useEffect(() => {
    if (!map) return;

    // Clean up before we do anything
    cleanUpFeatures('internal');
    cleanBeamMarkers('KA');

    if (
      !displaySatellites ||
      viewAsCustomer ||
      cacheId !== beamCache.cacheId ||
      mapOverlayContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP
    )
      return;
    for (const feature of beamCache.beams.features) {
      // update beam incident data
      if (satelliteBeamIncidentEvents && feature.properties)
        handleBeamOverlayImpactIncident(map, satelliteBeamIncidentEvents, feature, selectedSatBeamRef, true);
    }

    map.data.addGeoJson(beamCache.beams);

    const beamMarkers = [];
    for (const feature of beamCache.beams['features']) {
      const poly = polylabel(feature.geometry.coordinates, 1.0);
      // update beam incident style
      if (satelliteBeamIncidentEvents && feature.properties)
        handleBeamOverlayImpactIncident(map, satelliteBeamIncidentEvents, feature, selectedSatBeamRef);
      beamMarkers.push({
        markerPosition: {lat: poly[1], lng: poly[0]},
        id: `${feature.properties.abbreviation}-${feature.properties.beamId}`,
        secondaryLabel: feature.properties.beamId,
        label: feature.properties.abbreviation
      });
    }
    setBeamMarkers(beamMarkers);
    // eslint-disable-next-line
  }, [beamCache, viewAsCustomer, displaySatellites]);

  // Ku Beam Overlays
  useEffect(() => {
    if (!map) return;

    // Clean up before we do anything
    cleanBeamMarkers('KU');
    cleanUpFeatures('KuBeams');

    if (
      !displayKuSatellites ||
      kuCacheId !== kuBeamCache.cacheId ||
      mapOverlayContext === MapViewContext.CONNECTIVITY_OUTLOOK_MAP
    )
      return;
    map.data.addGeoJson(kuBeamCache.beams);

    const kuBeamMarkers = [];
    for (const feature of kuBeamCache.beams['features']) {
      const poly = polylabel(feature.geometry.coordinates, 1.0);

      kuBeamMarkers.push({
        markerPosition: {lat: poly[1], lng: poly[0]},
        id: `${feature.properties.satelliteName}-${feature.properties.description}`,
        label: feature.properties.satelliteRegionName
      });
    }
    setKuBeamMarkers(kuBeamMarkers);
    // eslint-disable-next-line
  }, [kuBeamCache, displayKuSatellites]);

  useEffect(() => {
    if (beamParams !== null && (!map || !selectedSatBeamData)) return;

    const networkType = selectedSatelliteDetails?.networkType;

    const isKaSatellites = networkType === 'internal' || networkType === 'internal-connected';
    const isKuSatellites = networkType === 'KuBeams' || networkType === 'ku-connected';

    const didSatelliteBeamChange =
      (isKaSatellites &&
        (selectedSatBeamData.satId !== selectedSatelliteDetails.satelliteId ||
          selectedSatBeamData.beamId !== selectedSatelliteDetails.beamId)) ||
      (isKuSatellites && selectedSatBeamData.satelliteName !== selectedSatelliteDetails.satelliteName);

    if (!didSatelliteBeamChange) return;

    // Lookup beam details
    let found = false;

    if (!networkType) return;
    let coveredSatellites = null;
    if (networkType === 'internal') {
      if (!beamCache.beams) return;
      coveredSatellites = beamCache.beams;
    } else if (networkType === 'internal-connected') {
      if (!connectedBeamCache.beams) return;
      coveredSatellites = connectedBeamCache.beams;
    } else if (networkType === 'KuBeams') {
      if (!kuBeamCache.beams) return;
      coveredSatellites = kuBeamCache.beams;
    } else if (networkType === 'ku-connected') {
      if (!kuConnectedBeamCache.beams) return;
      coveredSatellites = kuConnectedBeamCache.beams;
    }

    if (coveredSatellites && isArray(coveredSatellites['features'])) {
      for (const feature of coveredSatellites['features']) {
        const satBeamId = selectedSatBeamRef.current ? selectedSatBeamRef.current.id : null;
        // lookup satId-beamId
        if (feature.id === satBeamId) {
          // determine point for beam popup
          const poly = polylabel(feature.geometry.coordinates, 1.0);
          const point = {lng: poly[0], lat: poly[1]};
          // highlight selected beam, from shareLink/refresh, need to wait for map.data.features to be populated
          const currentFeature = map.data.getFeatureById(feature.id);
          // Determine satellite beam incident event impact and highlight
          const beamImpact: ESatBeamImpactType = currentFeature.getProperty(ESatBeamProps.IMPACT);
          const currentImpact = beamImpact ? beamImpact : ESatBeamImpactType.NONE;
          map.data.overrideStyle(currentFeature, getImpactEventStyle(currentImpact, ESatBeamEventType.CLICKED));
          // generate satellite beam data from feature
          const beamData = convertSatBeamData(
            point,
            feature,
            currentImpact,
            beamParams?.flightId,
            currentFeature.getProperty(ESatBeamProps.INCIDENTS)
          );
          setSelectedSatBeamData(beamData);
          found = true;
          break;
        }
      }
    }

    // Clear beam data if not found, due to deselect, overlay hidden, or connected beams changed,
    // or aircraft on ground or removed from map
    if (!found || (beamParams === null && Boolean(selectedSatBeamData?.flightId))) {
      dispatch({
        type: FleetMapAction.VALIDATE_SATELLITE_BEAMS,
        payload: {
          satBeam: selectedSatelliteDetails
        }
      });
      closeSatelliteBeamPopup();
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    selectedSatelliteDetails,
    beamParams,
    beamCache,
    connectedBeamCache,
    kuBeamCache,
    kuConnectedBeamCache,
    selectedSatBeamRef
  ]);

  const [selectedSatBeamData, setSelectedSatBeamData] = useState<ISatBeamData>({
    ...initialSatBeamData
  });

  const isKuBeamSelected = selectedSatBeamData?.networkType === 'KuBeams' ? true : false;

  const isSelectedSatelliteEnabled = isKuBeamSelected
    ? overlays.selectedKuSatellites.includes(selectedSatBeamData.details.networkRegionCode[0])
    : overlays.selectedKaSatellites.includes(selectedSatBeamData.satId);

  const showSelectedSatBeamPopup =
    (((selectedSatBeamData.networkType === 'internal' || selectedSatBeamData.networkType === 'internal-connected') &&
      isInternal &&
      !viewAsCustomer) ||
      selectedSatBeamData.networkType === 'KuBeams' ||
      selectedSatBeamData.networkType === 'ku-connected') &&
    Boolean(selectedSatBeamData.satId || selectedSatBeamData.satelliteName) &&
    (isSelectedSatelliteEnabled || connectedBeamsOverlayChecked);

  /**
   * Resets the selected satellite beam id in the component store and session storage
   */
  const resetStoreSelectedSatBeam = useCallback(() => {
    dispatch({type: FleetMapAction.RESET_SATELLITE_BEAMS});
  }, [dispatch]);

  /**
   * Callback that sets the selected satellite data in the state and session storage
   */
  const clearSelectedSatBeamCallback = useCallback(() => {
    resetStoreSelectedSatBeam();
  }, [resetStoreSelectedSatBeam]);

  /** Callback on Close Selected Satellite Beam Popup that resets state and style */
  const closeSatelliteBeamPopup = () => {
    // Clear highlight on prior selected sat beam
    handleBeamOverlayClose(map, selectedSatBeamRef);

    // Close beam popup
    clearSelectedSatBeamCallback();
    setSelectedSatBeamData({
      ...initialSatBeamData
    });
  };

  return (
    <>
      <Fade in={showMapOverlayPopup} timeout={250}>
        <CoveragePopupDiv
          id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'overlay', 'div')}
          searchBoxDisabled={searchBoxDisabled}
        >
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeOverlayPopup}>
            <div>
              <ScrollContainer maxHeight={Math.max(0, mapHeight - scrollContainerHeight)}>
                {Boolean(selectedAircraftData?.aircraftId) &&
                mapOverlayContext !== MapViewContext.CONNECTIVITY_OUTLOOK_MAP &&
                ((isInternal && !viewAsCustomer) ||
                  selectedAircraftData?.networkCapability === NetworkCapability.KU) ? (
                  <CoverageMapOuterContainer>
                    <CoverageMapContainer>
                      <div
                        id={getElementId(
                          MAP_ELID_SECTION,
                          MAP_ELID_CONTROLS_SUBSECTION,
                          `connectedBeamsOverlay`,
                          'div'
                        )}
                      >
                        Connected Beams
                      </div>
                      <HelpWrap>
                        <HelpToolTip
                          marginOverride="12px 0 0 4px"
                          message={'fleet_map.connected_beams.title_tooltip'}
                        />
                      </HelpWrap>
                      <CustomSwitchContainer>
                        <Switch
                          id={getElementId(
                            MAP_ELID_SECTION,
                            MAP_ELID_CONTROLS_SUBSECTION,
                            `connectedBeamsOverlay`,
                            'switch'
                          )}
                          color="primary"
                          checked={connectedBeamsOverlayChecked}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateShowConnectedOverlaySettings(event.currentTarget.checked);
                          }}
                        />
                      </CustomSwitchContainer>
                    </CoverageMapContainer>
                  </CoverageMapOuterContainer>
                ) : null}

                <CoverageMapTitle highlighted>KA COVERAGE {renderExpand(showKa, setShowKa)}</CoverageMapTitle>

                {showKa ? (
                  <CoverageMapOuterContainer>
                    <CoverageMapContainer>
                      <div>Ka Coverage</div>
                      <CustomSwitchContainer>
                        <Switch
                          id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'kaOverlay', 'switch')}
                          color="primary"
                          checked={kaOverlayChecked}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            updateKaOverlaySettings(event.currentTarget.checked)
                          }
                        />
                      </CustomSwitchContainer>
                    </CoverageMapContainer>
                    {!viewAsCustomer && mapOverlayContext !== MapViewContext.CONNECTIVITY_OUTLOOK_MAP ? (
                      Object.keys(kaSatelliteGroup).map((region, i) => (
                        <React.Fragment key={`satelliteGroup-${i}`}>
                          <CoverageMapTitle
                            className={getElementId(
                              MAP_ELID_SECTION,
                              MAP_ELID_CONTROLS_SUBSECTION,
                              `ka-regionsOverlay`,
                              'div'
                            )}
                          >
                            {region}
                          </CoverageMapTitle>
                          {kaSatelliteGroup[region].map((satellite, id) => (
                            <CoverageMapContainer key={`${satellite.name}-${id}`}>
                              <div
                                className={getElementId(
                                  MAP_ELID_SECTION,
                                  MAP_ELID_CONTROLS_SUBSECTION,
                                  `ka-satellitesOverlay`,
                                  'div'
                                )}
                              >
                                {satellite.name}
                              </div>
                              <CustomSwitchContainer>
                                <Switch
                                  id={getElementId(
                                    MAP_ELID_SECTION,
                                    MAP_ELID_CONTROLS_SUBSECTION,
                                    `${satellite.satelliteId}Overlay`,
                                    'switch'
                                  )}
                                  color="primary"
                                  checked={overlays.selectedKaSatellites.includes(satellite.satelliteId)}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateKaSatelliteOverlaySettings(event.currentTarget.checked, satellite.satelliteId)
                                  }
                                />
                              </CustomSwitchContainer>
                            </CoverageMapContainer>
                          ))}
                        </React.Fragment>
                      ))
                    ) : (
                      <></>
                    )}
                    <React.Fragment key={`regional-ka-service-plan-coverage`}>
                      <CoverageMapTitle
                        className={getElementId(
                          MAP_ELID_SECTION,
                          MAP_ELID_CONTROLS_SUBSECTION,
                          `ka-regionalServicePlanCoverage`,
                          'div'
                        )}
                      >
                        KA Regional Service Coverage
                      </CoverageMapTitle>
                      {KA_REGIONAL_PLAN_SERVICE_COVERAGE.map((regionInfo, id) => (
                        <CoverageMapContainer key={`ka-coverage-region-${id}`}>
                          <div
                            className={getElementId(
                              MAP_ELID_SECTION,
                              MAP_ELID_CONTROLS_SUBSECTION,
                              `ka-${regionInfo.region}-servicePlanCoverageOverlay`,
                              'div'
                            )}
                          >
                            {regionInfo.name}
                          </div>
                          <CustomSwitchContainer>
                            <Switch
                              id={getElementId(
                                MAP_ELID_SECTION,
                                MAP_ELID_CONTROLS_SUBSECTION,
                                `${regionInfo.region}RegionalPlanOverlay`,
                                'switch'
                              )}
                              color="primary"
                              checked={overlays.kaRegionalServiceCoverage?.includes(regionInfo.region)}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                updateKaRegionalOverlaySettings(event.currentTarget.checked, regionInfo.region)
                              }
                            />
                          </CustomSwitchContainer>
                        </CoverageMapContainer>
                      ))}
                    </React.Fragment>
                  </CoverageMapOuterContainer>
                ) : (
                  <></>
                )}
                <CoverageMapTitle highlighted>KU COVERAGE {renderExpand(showKu, setShowKu)}</CoverageMapTitle>
                {showKu ? (
                  <CoverageMapOuterContainer>
                    <CoverageMapContainer>
                      <div>Ku Coverage</div>
                      <CustomSwitchContainer>
                        <Switch
                          id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'kuOverlay', 'switch')}
                          color="primary"
                          checked={kuOverlayChecked}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            updateKuOverlaySettings(event.currentTarget.checked)
                          }
                        />
                      </CustomSwitchContainer>
                    </CoverageMapContainer>
                    {mapOverlayContext !== MapViewContext.CONNECTIVITY_OUTLOOK_MAP && kuSatelliteGroups.length > 0 ? (
                      kuSatelliteGroups.map(({region, kuSatellites}, i) => (
                        <React.Fragment key={`satelliteGroup-${i}`}>
                          <CoverageMapTitle
                            className={getElementId(
                              MAP_ELID_SECTION,
                              MAP_ELID_CONTROLS_SUBSECTION,
                              `ku-regionsOverlay`,
                              'div'
                            )}
                          >
                            {region}
                          </CoverageMapTitle>
                          {orderBy(kuSatellites, ['name'], ['asc']).map((satellite, id) => (
                            <CoverageMapContainer key={`${satellite.name}-${id}`}>
                              <div
                                className={getElementId(
                                  MAP_ELID_SECTION,
                                  MAP_ELID_CONTROLS_SUBSECTION,
                                  `ku-satellitesOverlay`,
                                  'div'
                                )}
                              >
                                {satellite.name}
                              </div>
                              <CustomSwitchContainer>
                                <Switch
                                  id={getElementId(
                                    MAP_ELID_SECTION,
                                    MAP_ELID_CONTROLS_SUBSECTION,
                                    `${satellite.id}Overlay`,
                                    'switch'
                                  )}
                                  color="primary"
                                  checked={overlays.selectedKuSatellites.includes(satellite.id)}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    updateKuSatelliteOverlaySettings(event.currentTarget.checked, satellite.id)
                                  }
                                />
                              </CustomSwitchContainer>
                            </CoverageMapContainer>
                          ))}
                        </React.Fragment>
                      ))
                    ) : (
                      <></>
                    )}
                    <React.Fragment key={`regional-ku-service-plan-coverage`}>
                      <CoverageMapTitle
                        className={getElementId(
                          MAP_ELID_SECTION,
                          MAP_ELID_CONTROLS_SUBSECTION,
                          `ku-regionalServicePlanCoverage`,
                          'div'
                        )}
                      >
                        KU Regional Service Coverage
                      </CoverageMapTitle>
                      {KU_REGIONAL_PLAN_SERVICE_COVERAGE.map((regionInfo, id) => (
                        <CoverageMapContainer key={`ku-coverage-region-${id}`}>
                          <div
                            className={getElementId(
                              MAP_ELID_SECTION,
                              MAP_ELID_CONTROLS_SUBSECTION,
                              `ku-${regionInfo.region}-servicePlanCoverageOverlay`,
                              'div'
                            )}
                          >
                            {regionInfo.name}
                          </div>
                          <CustomSwitchContainer>
                            <Switch
                              id={getElementId(
                                MAP_ELID_SECTION,
                                MAP_ELID_CONTROLS_SUBSECTION,
                                `${regionInfo.region}RegionalPlanOverlay`,
                                'switch'
                              )}
                              color="primary"
                              checked={overlays.kuRegionalServiceCoverage?.includes(regionInfo.region)}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                updateKuRegionalOverlaySettings(event.currentTarget.checked, regionInfo.region)
                              }
                            />
                          </CustomSwitchContainer>
                        </CoverageMapContainer>
                      ))}
                    </React.Fragment>
                  </CoverageMapOuterContainer>
                ) : null}
                {isInternal && !viewAsCustomer ? (
                  <>
                    <CoverageMapTitle highlighted>
                      Transmit Inhibit Zones {renderExpand(showTxInhibitZones, setShowTxInhibitZones)}
                    </CoverageMapTitle>
                    {showTxInhibitZones ? (
                      <>
                        <CoverageMapContainer>
                          <div style={{flexGrow: 1}}>Below 10K Feet</div>
                          <CustomSwitchContainer>
                            <Switch
                              id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'atiZones', 'switch')}
                              color="primary"
                              checked={txInhibitZonesCheckedBelow10kFeet}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                updateTxInhibitZonesSettings(event.currentTarget.checked, true)
                              }
                            />
                          </CustomSwitchContainer>
                        </CoverageMapContainer>
                        <CoverageMapContainer>
                          <div style={{flexGrow: 1}}>At All Altitudes</div>
                          <CustomSwitchContainer>
                            <Switch
                              id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'atiZones1', 'switch')}
                              color="primary"
                              checked={txInhibitZonesCheckedAtAllAltitudes}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                updateTxInhibitZonesSettings(event.currentTarget.checked, false)
                              }
                            />
                          </CustomSwitchContainer>
                        </CoverageMapContainer>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : null}

                {!disableTailTagConfig ? (
                  <>
                    <CoverageMapTitle highlighted>
                      Tag Configuration {renderExpand(showTagConfiguration, setShowTagConfiguration)}
                    </CoverageMapTitle>
                    {showTagConfiguration ? (
                      <>
                        <TailTagConfigurationContainer>
                          <Span style={{flexGrow: 1}}>Tail Tags</Span>
                          <CustomSwitchContainer>
                            <Switch
                              id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, 'tailTag', 'switch')}
                              color="primary"
                              checked={isTailTagOpen}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setTailTagOpen(event.currentTarget.checked);
                                sessionStorage.isTailTagOpen = !isTailTagOpen;
                              }}
                            />
                          </CustomSwitchContainer>
                        </TailTagConfigurationContainer>
                        <TailTagConfigurationContainer>
                          <Span style={{flexGrow: 1}}>Tail Tag Size</Span>
                          <CustomSwitchContainer>
                            <TailTagSmallSpan
                              id={getElementId(
                                MAP_ELID_SECTION,
                                MAP_ELID_CONTROLS_SUBSECTION,
                                'smallTailTag',
                                'switch'
                              )}
                              onClick={() => {
                                setShowLargePopup(false);
                                sessionStorage.isTagSizeLarge = Boolean(false);
                              }}
                            >
                              <LabelIcon
                                className={Boolean(showLargePopup) === false ? '' : classes.labelIcon}
                                fontSize="small"
                                color={Boolean(showLargePopup) === false ? 'primary' : 'disabled'}
                              />
                            </TailTagSmallSpan>
                            <TailTagLargeSpan
                              id={getElementId(
                                MAP_ELID_SECTION,
                                MAP_ELID_CONTROLS_SUBSECTION,
                                'largeTailTag',
                                'switch'
                              )}
                              onClick={() => {
                                setShowLargePopup(true);
                                sessionStorage.isTagSizeLarge = Boolean(true);
                              }}
                            >
                              <LabelIcon
                                className={Boolean(showLargePopup) === true ? '' : classes.labelIcon}
                                color={Boolean(showLargePopup) === true ? 'primary' : 'disabled'}
                              />
                            </TailTagLargeSpan>
                          </CustomSwitchContainer>
                        </TailTagConfigurationContainer>
                      </>
                    ) : null}{' '}
                  </>
                ) : (
                  <></>
                )}
              </ScrollContainer>

              <MapOverlayButtonWrapper
                id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, `showHide`, 'buttonWrapper')}
              >
                <StyledButton
                  id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, `hideAll`, 'button')}
                  onClick={() => showHideAll(false)}
                  disabled={totalChecked === 0}
                >
                  Hide All
                </StyledButton>
                <StyledButton
                  id={getElementId(MAP_ELID_SECTION, MAP_ELID_CONTROLS_SUBSECTION, `showAll`, 'button')}
                  onClick={() => showHideAll(true)}
                  disabled={totalChecked === totalChecks}
                >
                  Show All
                </StyledButton>
              </MapOverlayButtonWrapper>
            </div>
          </ClickAwayListener>
        </CoveragePopupDiv>
      </Fade>
      {showSelectedSatBeamPopup ? (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={selectedSatBeamData.beamCenter}
          getPixelPositionOffset={(x, y) => ({x: SAT_BEAM_POPUP_OFFSET_X, y: SAT_BEAM_POPUP_OFFSET_Y})}
        >
          <SelectedSatBeamPopup
            isInternal={isInternal}
            viewAsCustomer={viewAsCustomer}
            satBeamData={selectedSatBeamData}
            close={() => closeSatelliteBeamPopup()}
          />
        </OverlayView>
      ) : (
        <></>
      )}
      {beamMarkers && (
        <>
          {beamMarkers.map((beamMarker, id) => {
            return <BeamMarkerWithLabel key={`ka-beam-marker-${id}`} beamMarker={beamMarker} />;
          })}
        </>
      )}
      {kuBeamMarkers && (
        <>
          {kuBeamMarkers.map((beamMarker, id) => {
            return <BeamMarkerWithLabel key={`ku-beam-marker-${id}`} beamMarker={beamMarker} />;
          })}
        </>
      )}
      {kaConnectedBeamMarkers && (
        <>
          {kaConnectedBeamMarkers.map((beamMarker, id) => {
            return <BeamMarkerWithLabel key={`connected-ka-beam-marker-${id}`} beamMarker={beamMarker} />;
          })}
        </>
      )}
      {kuConnectedBeamMarkers && (
        <>
          {kuConnectedBeamMarkers.map((beamMarker, id) => {
            return <BeamMarkerWithLabel key={`connected-ku-beam-marker-${id}`} beamMarker={beamMarker} />;
          })}
        </>
      )}
    </>
  );
};

export default MapOverlayConfigurations;
