/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Summary Metrics Page component
 *
 */

//Common
import React, {useEffect, useMemo, useState, useRef} from 'react';
import moment from 'moment';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {DraggableProvidedDragHandleProps} from 'react-beautiful-dnd';
import {useStore} from '../../store/Store';
import useFetch from '../../utils/useFetch';
import {liveTimeOverrideEnabled} from '../../utils/config';
import Highcharts from 'highcharts';
// style and commom components
import {Card, CardContent} from '@mui/material';
import {
  CustomLink,
  DataToolTip,
  DatePicker,
  LoadingBar,
  LoadingBarType,
  LoadingSpinner
} from '@viasat/insights-components';
import PrintIcon from '@mui/icons-material/Print';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import FilterHookContainer from '../common/elements/filterSelector/FilterHookContainer';
import PageContainerTemplate from '../common/layout/page/PageContainerTemplate';
import BoldSpan from '../common/BoldSpan';
import ShareButton from '../common/elements/share/ShareButton';
import {Span} from '../mapView/aircraftPopups/AircraftPopupStyles';
import {PrintText} from '../connectivityOutlook/ConnectivityOutlookStyles';
import ViasatLogoIcon from '../common/theme/icons/ViasatLogoIcon';
import LiveTimeEntry from '../common/LiveTimeEntry';
import DragIndicator from '@mui/icons-material/DragIndicator';
//Reducer
import {DatePickerAction, DatePickerState} from '../../store/reducers/DatePickerReducer';
import {SUMMARY_METRICS_STORE_CONTEXT, SummaryMetricsAction} from '../../store/reducers/SummaryMetricsReducer';
import {FilterAction} from '../../store/reducers/FiltersReducer';
//utils
import {useFilteringParams, useQueryInputsDateRange} from '../../store/queries/queryUtils';
import {removeDatePickerGuardrails} from '../../utils/config';
import {getSummaryMetricsFilters, getDatePickerPresets, QUARTER} from './summaryMetricsUtil';
import {getFilterOptions, printHeaderFilters, translateFiltersArrayToObject} from '../../utils/filterUtils';
import {getActionTypeWithContext} from '../../store/reducerUtils';
import {DATE_TIME_FORMAT_WITH_MILLISECONDS, formatDurationValue} from '../../utils/DateTimeUtils';
import useMetricsCardDisplay from './useMetricsCardDisplay';
//queries
import endUsersQuery, {EndUser} from '../../store/queries/common/endUsersQuery';
import tailInfoQuery, {TailInfo} from '../../store/queries/flightList/tailInfoQuery';
import flightListTotalQuery, {FlightListTotalType} from '../../store/queries/flightList/flightListTotalQuery';
import totalConnectedTimesQuery, {
  ITotalConnectedTime
} from '../../store/queries/summaryMetrics/totalConnectedTimesQuery';
import totalActiveAircraftQuery from '../../store/queries/summaryMetrics/totalActiveAircraftQuery';
import totalDataUsageQuery, {ITotalDataUsage} from '../../store/queries/summaryMetrics/totalDataUsageQuery';
import flightPathHistoryQuery from '../../store/queries/aircraftStatusList/flightPathHistoryQuery';
import trafficCompositionCumulativeQuery from '../../store/queries/summaryMetrics/trafficCompositionCumulativeQuery';
import {
  dataUsageTrendQuery1,
  dataUsageTrendQuery2,
  dataUsageTrendQuery3,
  dataUsageTrendQuery4,
  dataUsageTrendQuery5,
  dataUsageTrendQuery6,
  IMonthlyUsage
} from '../../store/queries/summaryMetrics/dataUsageTrend';
//colors
import {
  LIGHT_GREY,
  CASE_LIST_TEXT_COLOR,
  VIASAT_LOGO_ICON_COLOR,
  FLIGHT_DETAILS_DECOMMISSIONED_BANNER_BG_COLOR,
  DARK_FLIGHT_ALERT_ICON_COLOR
} from '../common/theme/Colors';

import {
  CardDataDivider,
  CardDataWithAverage,
  CardTitle,
  ChartHeader,
  ChartHeaderActions,
  ChartTitle,
  ChartWrapper,
  DataLabel,
  DataSpace,
  DataValue,
  DragContainer,
  LoadingIndicator,
  MiddleSectionSummeryMetricsRow,
  MiddleSectionSummeryMetrics,
  PrintFooter,
  PrintHeader,
  Spinner,
  SummaryMetricsBody,
  TopSectionSummaryMetricsContainer,
  SummaryMetricAircraftInfoListContainer,
  PrintHeaderTitle,
  PrintHeaderTitleCount,
  LineBreak,
  PrintHeaderLabel,
  PrintHeaderSingleBottomBorder,
  PrintHeaderMultiBottomBorder,
  LabelText,
  ValueText,
  PrintFilterContainer
} from './SummaryMetricsStyles';
import totalRawDataUsageQuery, {ITotalRawDataUsage} from '../../store/queries/summaryMetrics/totalRawDataUsageQuery';
import activeAircraftInfoQuery from '../../store/queries/summaryMetrics/activeAircraftInfoQuery';
import monthlyAircraftCountQuery, {
  IMonthlyAircraftCount
} from '../../store/queries/summaryMetrics/monthlyAircraftCountQuery';
import SummaryMetricsAircraftDetails from './SummaryMetricsAircraftDetails';
import ReactToPrint from 'react-to-print';
import {getBrowserName} from '../../utils/browserUtils';
import StyledButton from '../common/StyledButton';
import {FLIGHT_LIST_STORE_CONTEXT} from '../../store/reducers/FlightListReducer';
import {AlertContainer, AlertMessage} from '../common/commonStyles';


export interface IPrintFilter {
  [key: string]: string[];
}
interface IPrintFilterProps {
  allFilters: IPrintFilter;
}
const TOP_SECTION_SUMMARY_CARDS_CONFIG = [
  {
    id: 'totalFlights',
    title: 'Flights',
    tooltipText: {
      single: 'The total number of flights for the selected aircraft over the selected date range.',
      fleet:
        'The total number of aircraft currently selected, and the average number of flights per aircraft over the selected date range.'
    },
    hasAverage: false,
    avgTitleSingle: 'Total Flights',
    avgTitleFleet: 'Average per Aircraft',
    totalTitle: 'Aircraft',
    value: 0,
    transformData: null,
    isLoading: true,
    units: null
  },
  {
    id: 'flightHours',
    title: 'Flight Hours',
    tooltipText: {
      single: 'The total number of flight hours  and the average time per flight over the selected date range.',
      fleet:
        'The total number of flight hours for all selected aircraft and average flight time for all flights over the selected date range.'
    },
    hasAverage: true,
    avgTitleSingle: 'Average per Flight',
    avgTitleFleet: 'Average per Aircraft',
    totalTitle: 'Total',
    value: 0,
    transformData: formatDurationValue,
    isLoading: true,
    units: null
  },
  {
    id: 'connectedHours',
    title: 'Connected Hours',
    tooltipText: {
      single:
        'The total connected time and the average connected time for the selected aircraft over the selected date range. This includes times when the aircraft may have been connected but did not fly.',
      fleet:
        'The total connected time for all selected aircraft, and the average connected time per aircraft over the selected date range.  This includes times when the aircraft may have been connected but did not fly.'
    },
    hasAverage: true,
    avgTitleSingle: 'Average per Flight',
    avgTitleFleet: 'Average per Aircraft',
    totalTitle: 'Total',
    value: 0,
    transformData: formatDurationValue,
    isLoading: true,
    units: null
  },
  {
    id: 'dataUsage',
    title: 'Data Usage',
    tooltipText: {
      single:
        'Total data used for connected time and average data per hour used by the selected aircraft over the selected date range.',
      fleet:
        'Total data used for connected time and average data used per aircraft for all selected aircraft and the selected date range.'
    },
    hasAverage: true,
    avgTitleSingle: 'Average per Hour',
    avgTitleFleet: 'Average per Aircraft',
    totalTitle: 'Total',
    value: 0,
    isLoading: true,
    units: ' GB'
  }
];

const DragComponent: React.FC<{draggableProps: DraggableProvidedDragHandleProps}> = ({draggableProps}) => {
  return (
    <div {...draggableProps}>
      <DragIndicator fontSize="small" style={{color: LIGHT_GREY}} />
    </div>
  );
};

export const ChartCard: React.FC<{
  isLoading: boolean;
  id: string;
  borderColor: string;
  children: JSX.Element;
  title: string;
  helpText: string;
  headerActions?: JSX.Element;
  draggableProps: DraggableProvidedDragHandleProps;
}> = ({isLoading, id, borderColor, children, title, helpText, headerActions, draggableProps}) => {
  return (
    <ChartWrapper borderColor={borderColor} id={id + '-container'}>
      {isLoading ? (
        <>
          <LoadingIndicator>
            <LoadingBar id={id + `-loading`} type={LoadingBarType.stretch} height={4} />
          </LoadingIndicator>
          <ChartTitle>
            <ChartHeaderActions>
              <DragContainer>
                <DragComponent draggableProps={draggableProps} />
              </DragContainer>
            </ChartHeaderActions>
          </ChartTitle>
        </>
      ) : (
        <>
          <ChartHeader>
            <ChartTitle id={id + '-title'}>
              {title}
              <DataToolTip
                arrow
                placement="top"
                enterDelay={250}
                title={
                  <div style={{lineHeight: '18px', width: '300px', margin: '11px 13px'}}>
                    <span style={{fontWeight: 600}}>{helpText}</span>
                  </div>
                }
              >
                <InfoOutlinedIcon
                  sx={{
                    marginLeft: '5px',
                    marginTop: '6px',
                    width: 16,
                    height: 16,
                    color: LIGHT_GREY
                  }}
                />
              </DataToolTip>
              <ChartHeaderActions>
                {headerActions}
                <DragContainer>
                  <DragComponent draggableProps={draggableProps} />
                </DragContainer>
              </ChartHeaderActions>
            </ChartTitle>
            <CardDataDivider />
          </ChartHeader>
          {children}
        </>
      )}
    </ChartWrapper>
  );
};

export const PrintFilters: React.FC<IPrintFilterProps> = ({allFilters}) => {
  let filterKeys = Object.keys(allFilters);
  return (
    <PrintFilterContainer>
      {filterKeys.map((filter, id) => {
        return (
          <>
            <PrintHeaderLabel>
              <LabelText>Filter {id + 1}</LabelText>{' '}
              <ValueText>
                {filter}:&nbsp;{allFilters[filter]}
              </ValueText>
            </PrintHeaderLabel>
            <LineBreak />
          </>
        );
      })}
    </PrintFilterContainer>
  );
};
const SummaryMetricsPage: React.FC<any> = () => {
  const {store, dispatch} = useStore();
  const printComponentRef = useRef();
  const datePickerState = store.summaryMetrics.dateRange;
  const groupCode = store.customer.current.code;
  const cardOrder = store.summaryMetrics.cardOrder;
  const {
    filters: {filters = []}
  } = store.summaryMetrics;
  const {
    init: {isInternal, isValueAddedReseller}
  } = store;

  let tailIdFilters = [];
  let customerFilters = [];
  let serialNumberFilters = [];
  let networkFilters = [];
  let aircraftTypeFilters = [];

  filters.map((filter) => {
    if (filter.domainOptionsKey === 'tailId') {
      tailIdFilters?.push(filter.rangeOptionsKeys.join(', '));
    }
    if (filter.domainOptionsKey === 'customer') {
      customerFilters?.push(filter.rangeOptionsKeys.join(', '));
    }
    if (filter.domainOptionsKey === 'serialNumber') {
      serialNumberFilters?.push(filter.rangeOptionsKeys.join(', '));
    }
    if (filter.domainOptionsKey === 'networkCapability') {
      networkFilters?.push(filter.rangeOptionsKeys.join(', '));
    }
    if (filter.domainOptionsKey === 'aircraftType') {
      aircraftTypeFilters?.push(filter.rangeOptionsKeys.join(', '));
    }
    return null;
  });

  const [topSectionCards, setTopSectionCards] = useState(TOP_SECTION_SUMMARY_CARDS_CONFIG);
  const queryParams = useQueryInputsDateRange(
    store.customer.current.code,
    datePickerState.startDate,
    datePickerState.endDate
  );

  const [isMapMinimized, setMapMinimizedState] = useState(false);
  const [dataUsageTrend, setDataUsageTrend] = useState<IMonthlyUsage[]>(null);
  const [rollingUsage, setRollingUsage] = useState<IMonthlyUsage[]>(null);

  const {cardRows, onDragEnd} = useMetricsCardDisplay(cardOrder, dispatch);

  // Prerequisite queries

  // End Users
  const {data: endUsersData, isLoading: isEndUsersLoading} = useFetch<EndUser[]>(endUsersQuery, queryParams);
  const isEndUser = endUsersData && endUsersData.length === 0;

  // Tail Info
  const {data: tailInfoData, isLoading: isTailInfoLoading} = useFetch<TailInfo[]>(tailInfoQuery, queryParams);

  const arePrereqsLoading = isEndUsersLoading || isTailInfoLoading; // Add other prereq loading states here

  // To prevent extra queries, leave things as undefined until all prereqs are done
  const summaryMetricsFilters = useMemo(
    () => (arePrereqsLoading ? undefined : getSummaryMetricsFilters(isEndUser, isInternal, isValueAddedReseller)),
    [arePrereqsLoading, isEndUser, isInternal, isValueAddedReseller]
  );

  const queryParamsWithFiltering = useFilteringParams(queryParams, summaryMetricsFilters, filters);

  // Total Flights
  const {data: totalFlightsData, isLoading: isTotalFlightsLoading} = useFetch<FlightListTotalType>(
    flightListTotalQuery,
    queryParamsWithFiltering
  );
  // Total ConnectedTimes
  const {data: totalConnectedData, isLoading: isTotalConnectedTimesLoading} = useFetch<ITotalConnectedTime>(
    totalConnectedTimesQuery,
    queryParamsWithFiltering
  );

  const {data: totalActiveAircraftData, isLoading: isTotalActiveAircraftLoading} = useFetch<any>(
    totalActiveAircraftQuery,
    queryParamsWithFiltering
  );

  const {data: activeAircraftInfoData, isLoading: isActiveAircraftInfoLoading} = useFetch<any>(
    activeAircraftInfoQuery,
    queryParamsWithFiltering
  );

  const {data: flightPathHistory, isLoading: isFlightPathHistoryLoading} = useFetch<any>(
    flightPathHistoryQuery,
    queryParamsWithFiltering
  );

  // Total Data usage
  const {data: totalDataUsageData, isLoading: isTotalDataUsageLoading} = useFetch<ITotalDataUsage>(
    totalDataUsageQuery,
    queryParamsWithFiltering
  );

  // Total raw data usage
  const {data: totalRawDataUsageData, isLoading: isTotalRawDataUsageLoading} = useFetch<ITotalRawDataUsage>(
    totalRawDataUsageQuery,
    queryParamsWithFiltering
  );

  const areDataPrereqsLoading = isTotalActiveAircraftLoading || isTotalConnectedTimesLoading || isTotalFlightsLoading;
  const areDataUsageReqsLoading = isTotalDataUsageLoading || isTotalRawDataUsageLoading;

  const {data: trafficCompositionCumulative, isLoading: isTrafficCompositionCumulativeLoading} = useFetch<any>(
    trafficCompositionCumulativeQuery,
    queryParamsWithFiltering
  );

  // time overide states
  const [{timeOverrideEnable, timeOverrideValue}, setTimeOverride] = useState({
    timeOverrideEnable: sessionStorage.timeOverrideEnable === 'true' ? true : false,
    timeOverrideValue: sessionStorage.timeOverrideValue ? moment(sessionStorage.timeOverrideValue) : moment.utc()
  });

  const [timestampOverride, setTimestampOverride] = useState(
    timeOverrideEnable
      ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
      : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
  );

  const updateTimeOverride = (timeOverrideEnable: boolean, timeOverrideValue: moment.Moment) => {
    setTimeOverride({timeOverrideEnable, timeOverrideValue});
    sessionStorage.timeOverrideEnable = timeOverrideEnable;
    sessionStorage.timeOverrideValue = timeOverrideValue;
    setTimestampOverride(
      timeOverrideEnable
        ? moment(timeOverrideValue).format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
        : moment.utc().format(DATE_TIME_FORMAT_WITH_MILLISECONDS)
    );
  };

  //First Quarter - data usage Trend
  const queryParamsForFirstQuarterUsage = useMemo(() => {
    return {
      groupCode,
      currentTstamp: timestampOverride,
      quarter: QUARTER.FIRST
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForFirstQuarterUsageWithFiltering = useFilteringParams(
    queryParamsForFirstQuarterUsage,
    summaryMetricsFilters,
    filters
  );
  const {data: firstQuarterUsage, isLoading: isFirstQuarterUsageLoading} = useFetch<IMonthlyUsage[]>(
    dataUsageTrendQuery1,
    queryParamsForFirstQuarterUsageWithFiltering
  );

  //Second Quarter - data usage Trend
  const queryParamsForSecondQuarterUsage = useMemo(() => {
    return {
      groupCode,
      currentTstamp: timestampOverride,
      quarter: QUARTER.SECOND
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForSecondQuarterUsageWithFiltering = useFilteringParams(
    queryParamsForSecondQuarterUsage,
    summaryMetricsFilters,
    filters
  );
  const {data: secondQuarterUsage, isLoading: isSecondQuarterUsageLoading} = useFetch<IMonthlyUsage[]>(
    dataUsageTrendQuery2,
    queryParamsForSecondQuarterUsageWithFiltering
  );

  //Third Quarter - data usage Trend
  const queryParamsForThirdQuarterUsage = useMemo(() => {
    return {
      groupCode,
      currentTstamp: timestampOverride,
      quarter: QUARTER.THIRD
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForThirdQuarterUsageWithFiltering = useFilteringParams(
    queryParamsForThirdQuarterUsage,
    summaryMetricsFilters,
    filters
  );
  const {data: thirdQuarterUsage, isLoading: isThirdQuarterUsageLoading} = useFetch<IMonthlyUsage[]>(
    dataUsageTrendQuery3,
    queryParamsForThirdQuarterUsageWithFiltering
  );

  //Fourth Quarter - data usage Trend
  const queryParamsForFourthQuarterUsage = useMemo(() => {
    return {
      groupCode,
      currentTstamp: timestampOverride,
      quarter: QUARTER.FOURTH
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForFourthQuarterUsageWithFiltering = useFilteringParams(
    queryParamsForFourthQuarterUsage,
    summaryMetricsFilters,
    filters
  );
  const {data: fourthQuarterUsage, isLoading: isFourthQuarterUsageLoading} = useFetch<IMonthlyUsage[]>(
    dataUsageTrendQuery4,
    queryParamsForFourthQuarterUsageWithFiltering
  );

  //Previous year 3rd Quarter usage
  const queryParamsForFifthQuarter = useMemo(() => {
    return {
      groupCode,
      currentTstamp: moment.utc(timestampOverride).add(-12, 'month').toISOString(),
      quarter: QUARTER.THIRD
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForFifthQuarterWithFiltering = useFilteringParams(
    queryParamsForFifthQuarter,
    summaryMetricsFilters,
    filters
  );
  const {data: fifthQuarterUsage, isLoading: isFifthQuarterLoading} = useFetch<IMonthlyUsage[]>(
    dataUsageTrendQuery5,
    queryParamsForFifthQuarterWithFiltering
  );

  //Previous year 4th  Quarter usage
  const queryParamsForSixthQuarter = useMemo(() => {
    return {
      groupCode,
      currentTstamp: moment.utc(timestampOverride).add(-12, 'month').toISOString(),
      quarter: QUARTER.FOURTH
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForSixthQuarterWithFiltering = useFilteringParams(
    queryParamsForSixthQuarter,
    summaryMetricsFilters,
    filters
  );
  const {data: sixthQuarterUsage, isLoading: isSixthQuarterLoading} = useFetch<IMonthlyUsage[]>(
    dataUsageTrendQuery6,
    queryParamsForSixthQuarterWithFiltering
  );

  // Monthly Aircraft count - data usage Trend
  const queryMonthlyAircraftCount = useMemo(() => {
    return {
      groupCode,
      currentTstamp: timestampOverride
    };
  }, [groupCode, timestampOverride]);

  const queryParamsForMonthlyAircraftCountWithFiltering = useFilteringParams(
    queryMonthlyAircraftCount,
    summaryMetricsFilters,
    filters
  );
  const {data: monthlyAircraftCount, isLoading: isMonthlyAircraftCountLoading} = useFetch<IMonthlyAircraftCount[]>(
    monthlyAircraftCountQuery,
    queryParamsForMonthlyAircraftCountWithFiltering
  );

  const isDataUsageTrendLoading =
    isFirstQuarterUsageLoading &&
    isSecondQuarterUsageLoading &&
    isThirdQuarterUsageLoading &&
    isFourthQuarterUsageLoading &&
    isMonthlyAircraftCountLoading;

  const isRollingUsageLoading =
    isFirstQuarterUsageLoading ||
    isSecondQuarterUsageLoading ||
    isThirdQuarterUsageLoading ||
    isFourthQuarterUsageLoading ||
    isSixthQuarterLoading ||
    isFifthQuarterLoading;

  useEffect(() => {
    let dataUsageTrend = [];

    !isFirstQuarterUsageLoading && firstQuarterUsage && dataUsageTrend.push(...firstQuarterUsage);
    !isSecondQuarterUsageLoading && secondQuarterUsage && dataUsageTrend.push(...secondQuarterUsage);
    !isThirdQuarterUsageLoading && thirdQuarterUsage && dataUsageTrend.push(...thirdQuarterUsage);
    !isFourthQuarterUsageLoading && fourthQuarterUsage && dataUsageTrend.push(...fourthQuarterUsage);
    setDataUsageTrend([...dataUsageTrend]);
    !isSixthQuarterLoading && sixthQuarterUsage && dataUsageTrend.unshift(...sixthQuarterUsage);
    !isFifthQuarterLoading && fifthQuarterUsage && dataUsageTrend.unshift(...fifthQuarterUsage);
    setRollingUsage([...dataUsageTrend]);
  }, [
    firstQuarterUsage,
    isFirstQuarterUsageLoading,
    secondQuarterUsage,
    isSecondQuarterUsageLoading,
    thirdQuarterUsage,
    isThirdQuarterUsageLoading,
    fourthQuarterUsage,
    isFourthQuarterUsageLoading,
    fifthQuarterUsage,
    isFifthQuarterLoading,
    sixthQuarterUsage,
    isSixthQuarterLoading
  ]);

  useEffect(() => {
    let updatedCards = TOP_SECTION_SUMMARY_CARDS_CONFIG;
    updatedCards[0].isLoading = areDataPrereqsLoading;
    updatedCards[1].isLoading = areDataPrereqsLoading;
    updatedCards[2].isLoading = areDataPrereqsLoading;
    updatedCards[3].isLoading = areDataUsageReqsLoading;
    updatedCards[0].value = totalActiveAircraftData?.totalAircraft;
    updatedCards[1].value = totalConnectedData?.inFlightConnectedTime;
    updatedCards[2].value = totalConnectedData?.totalConnectedTime;
    updatedCards[3].value = totalRawDataUsageData ? parseFloat(String(totalRawDataUsageData.dataUsage?.toFixed(1))) : 0;
    setTopSectionCards([...updatedCards]);
  }, [
    totalActiveAircraftData,
    totalConnectedData,
    totalFlightsData,
    totalDataUsageData,
    totalRawDataUsageData,
    areDataPrereqsLoading,
    areDataUsageReqsLoading
  ]);

  // useEffect Purpose: Update the options available for filtering each of the filterable columns
  useEffect(() => {
    if (tailInfoData && endUsersData && summaryMetricsFilters) {
      const {domainOptions, rangeOptions} = getFilterOptions({tailInfoData, endUsersData}, summaryMetricsFilters);

      dispatch({
        type: getActionTypeWithContext(FilterAction.SET_DOMAIN_RANGE_OPTIONS, SUMMARY_METRICS_STORE_CONTEXT),
        payload: {
          domainOptions,
          rangeOptions
        }
      });
    }
  }, [tailInfoData, endUsersData, dispatch, summaryMetricsFilters]);

  // This will remove the charts tooltip from DOM whenever user navigates to other views
  useEffect(() => {
    return () => {
      document.querySelectorAll('.highcharts-tooltip-container').forEach((el) => el.remove());
    };
  }, []);

  const cardOrderAsString = JSON.stringify(cardOrder);

  useEffect(() => {
    setTimeout(function () {
      Highcharts.charts.forEach((chart, index) => {
        if (chart) {
          chart.setSize(chart.container.parentElement.clientWidth, chart.container.parentElement.clientHeight);
        }
      });
    }, 1);
  }, [cardOrderAsString]);

  const onDatePickerChange = (newState: DatePickerState) => {
    dispatch({
      type: SummaryMetricsAction.SET_DATE_RANGE,
      payload: {...newState, viewContext: SUMMARY_METRICS_STORE_CONTEXT}
    });
  };

  const handleDecommissionedFlightsClick = () => {
    const decommissionedFilters = filters.map((obj) => {
      if (obj.domainOptionsKey === 'aircraftStatus') {
        return {...obj, rangeOptionsKeys: ['Decommissioned']};
      }
      return obj;
    });
    dispatch({
      type: getActionTypeWithContext(FilterAction.SET_SELECTED_FILTER, FLIGHT_LIST_STORE_CONTEXT),
      payload: {
        filters: decommissionedFilters
      }
    });
    dispatch({type: DatePickerAction.SET_DATE_RANGE, payload: datePickerState});
  };
  const idBase = `summaryMetrics`;
  const scrollContainerRef = useRef(null);
  const copyrightYear = moment().utc().year();
  const allFilters = printHeaderFilters(
    tailIdFilters,
    customerFilters,
    serialNumberFilters,
    networkFilters,
    aircraftTypeFilters
  );

  let cardProps = {
    dataUsageCard: {
      key: 'DataUsageCard',
      isLoading: isTotalDataUsageLoading || isTotalRawDataUsageLoading,
      totalDataUsageData: totalDataUsageData,
      totalRawDataUsageData: totalRawDataUsageData,
      startDate: datePickerState.startDate,
      endDate: datePickerState.endDate,
      totalConnectedTime: totalConnectedData,
      draggbleProps: null
    },
    flightPathHistoryCard: {
      key: 'FlightPathHistoryCard',
      flightPathData: flightPathHistory,
      setMinimize: () => setMapMinimizedState(true),
      isLoading: isFlightPathHistoryLoading,
      hoverLineContainerRef: scrollContainerRef,
      isMinimized: isMapMinimized,
      chartTimeSettings: null,
      liveFlightOffset: null,
      parentLeftOffset: null,
      draggleProps: null
    },
    trafficCompositionCard: {
      key: 'TrafficCompositionCumulativeCard',
      tcCumulativeData: trafficCompositionCumulative,
      totalDataUsageData: totalDataUsageData,
      isLoading: isTrafficCompositionCumulativeLoading || isTotalDataUsageLoading,
      startDate: datePickerState.startDate,
      endDate: datePickerState.endDate,
      draggableProps: null
    },
    dataUsageTrendCard: {
      key: 'DataUsageTrendCard',
      dataUsageTrend: dataUsageTrend,
      rollingUsage: rollingUsage,
      monthOverAircraftCount: monthlyAircraftCount || [],
      isLoading: isDataUsageTrendLoading,
      isRollingUsageLoading: isRollingUsageLoading,
      draggableProps: null
    }
  };

  return (
    <PageContainerTemplate
      getFullElementId={(name, type) => `${idBase}--pageContainer__${name}-${type}`}
      leftStack={[
        <BoldSpan style={{width: '230px'}}>
          Summary Metrics
          {areDataPrereqsLoading ? (
            <Spinner>
              <LoadingSpinner id={`${idBase}-loading-spinner`} isSpinner={true} size={15} />
            </Spinner>
          ) : (
            <Span style={{color: CASE_LIST_TEXT_COLOR, marginLeft: '2px'}}>
              ({totalActiveAircraftData?.totalAircraft} Aircraft)
            </Span>
          )}
        </BoldSpan>,
        <DatePicker
          key="datePicker"
          getFullElementId={(name: string, type: string) => `${idBase}-DatePicker__${name}-${type}`}
          currentDateRange={datePickerState}
          onSetDateRange={onDatePickerChange}
          removeDatePickerGuardrails={removeDatePickerGuardrails}
          datePickerPresets={getDatePickerPresets()}
          datePickerGuardrailLimitMonths={13}
        />,
        <FilterHookContainer
          key="filters"
          idPrefix={idBase}
          storeContext={SUMMARY_METRICS_STORE_CONTEXT}
          handleFilterChange={() => {}}
        />,
        <>
          {liveTimeOverrideEnabled ? (
            <div key="manual-date-time">
              <LiveTimeEntry
                idBase="sm-time-override"
                enable={timeOverrideEnable}
                value={timeOverrideValue}
                onChange={updateTimeOverride}
              />
            </div>
          ) : null}
        </>
      ]}
      rightStack={[
        <ReactToPrint
          trigger={() => (
            <StyledButton
              key="print-btn"
              id="summary-metrics--header__print-button"
              disabled={areDataPrereqsLoading || areDataUsageReqsLoading || arePrereqsLoading}
            >
              <PrintIcon sx={{fontSize: 24}} />
              <PrintText>Print</PrintText>
            </StyledButton>
          )}
          content={() => printComponentRef.current}
        />,
        <ShareButton
          idBase={idBase}
          key={`share${idBase}`}
          disabled={areDataPrereqsLoading || areDataUsageReqsLoading || arePrereqsLoading}
          customFilters={{
            ...translateFiltersArrayToObject(filters)
          }}
          viewContext={SUMMARY_METRICS_STORE_CONTEXT}
        />
      ]}
    >
      <div ref={printComponentRef}>
        <SummaryMetricsBody browserType={getBrowserName()}>
          {totalActiveAircraftData?.totalAircraft > 1 ? (
            <PrintHeader>
              <PrintHeaderTitle>
                Summary Metrics for
                <PrintHeaderTitleCount>{totalActiveAircraftData?.totalAircraft}</PrintHeaderTitleCount> Aircraft
              </PrintHeaderTitle>
              <LineBreak />
              <PrintHeaderLabel>
                Date: {datePickerState.startDate} - {datePickerState.endDate}
              </PrintHeaderLabel>
              <PrintHeaderMultiBottomBorder />
            </PrintHeader>
          ) : (
            <PrintHeader>
              <PrintHeaderTitle>
                Summary Metrics for
                <PrintHeaderTitleCount>{totalActiveAircraftData?.totalAircraft}</PrintHeaderTitleCount> Aircraft
              </PrintHeaderTitle>
              <PrintHeaderLabel>
                <LabelText>Customer</LabelText>
                <ValueText> {activeAircraftInfoData ? activeAircraftInfoData[0]?.customer : null}</ValueText>
              </PrintHeaderLabel>
              <LineBreak />
              <PrintHeaderLabel>
                <LabelText>Serial Number</LabelText>
                <ValueText> {activeAircraftInfoData ? activeAircraftInfoData[0]?.serialNumber : null}</ValueText>
              </PrintHeaderLabel>
              <LineBreak />
              <PrintHeaderLabel>
                <LabelText>Tail ID</LabelText>
                <ValueText> {activeAircraftInfoData ? activeAircraftInfoData[0]?.tailId : null}</ValueText>
              </PrintHeaderLabel>
              <LineBreak />
              <PrintHeaderLabel>
                <LabelText>Date </LabelText>
                <ValueText>
                  {datePickerState.startDate} - {datePickerState.endDate}
                </ValueText>
                <LineBreak />
              </PrintHeaderLabel>
              <LineBreak />
              <PrintFilters allFilters={allFilters} />
              <PrintHeaderSingleBottomBorder />
            </PrintHeader>
          )}

          {!isTotalActiveAircraftLoading && totalActiveAircraftData?.decommissionedAircraft > 0 && (
            <AlertContainer width={120} bgColor={FLIGHT_DETAILS_DECOMMISSIONED_BANNER_BG_COLOR}>
              <AlertMessage id="summary-metrics--header__decommissionedAircraft_alert">
                <ErrorRoundedIcon sx={{color: DARK_FLIGHT_ALERT_ICON_COLOR, width: 24, height: 24}} />
                <Span className="decommissionedBanner">
                  <CustomLink
                    id= 'summary-metrics--header__decommissiondAircraftCount'
                    to={'/lists/flight'}
                    handleClick={() => {
                      handleDecommissionedFlightsClick();
                    }}
                  >
                    {totalActiveAircraftData?.decommissionedAircraft}&nbsp;
                  </CustomLink>
                  of the selected aircraft have been decommissioned
                </Span>
              </AlertMessage>
            </AlertContainer>
          )}

          <TopSectionSummaryMetricsContainer>
            {topSectionCards.map((card, idx) => {
              const isFleetLevel = totalActiveAircraftData?.totalAircraft > 1;
              let averageData = !isFleetLevel
                ? Math.round(card.value / totalFlightsData)
                : Math.round(card.value / totalActiveAircraftData?.totalAircraft);
              // Change the average on Flights card when the view is on Single vs Fleet level
              if (idx === 0) {
                averageData = !isFleetLevel
                  ? totalFlightsData
                  : Math.round(totalFlightsData / totalActiveAircraftData?.totalAircraft);
              }
              // Change the average calculation on data usage when the view is on Single Tail level
              if (idx === topSectionCards.length - 1 && !isFleetLevel) {
                averageData = Math.round(card.value / 60);
              }
              return (
                <Card
                  id={`${card.id}-card`}
                  key={`${idx}-card`}
                  sx={{height: 125, width: 'calc(25% - 24px)', margin: '16px 0px 16px 0px', boxShadow: 'none'}}
                >
                  {card.isLoading ? (
                    <LoadingIndicator>
                      <LoadingBar id={`aircraftStatusCardView-loading`} type={LoadingBarType.stretch} height={4} />
                    </LoadingIndicator>
                  ) : (
                    <CardContent
                      sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <CardTitle fontSize={14} width={160}>
                        {card.title}
                        <DataToolTip
                          arrow
                          placement="top"
                          enterDelay={250}
                          title={
                            <>
                              <div style={{lineHeight: '18px', margin: '11px 13px'}}>
                                <div>
                                  <span style={{fontWeight: 600}}>
                                    {!isFleetLevel ? card.tooltipText.single : card.tooltipText.fleet}
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        >
                          <InfoOutlinedIcon sx={{margin: '5px 0 0 5px', width: 16, height: 16, color: LIGHT_GREY}} />
                        </DataToolTip>
                      </CardTitle>

                      <CardDataDivider />

                      <CardDataWithAverage>
                        <DataLabel>{card.totalTitle}</DataLabel>
                        <DataValue id={`${card.id}-card-value`} fontSize={16} width={80}>
                          {card.value
                            ? card.transformData
                              ? card.transformData(card.value, true)
                              : Number(card.value).toLocaleString()
                            : '--'}
                          {card.units ? card.units : ''}
                        </DataValue>
                        <DataSpace />
                      </CardDataWithAverage>
                      <CardDataWithAverage>
                        <DataLabel>{isFleetLevel ? card.avgTitleFleet : card.avgTitleSingle}</DataLabel>
                        <DataValue id={`${card.id}-card-avg-value`} fontSize={16} width={80}>
                          {card.value
                            ? card.transformData
                              ? card.transformData(averageData, true)
                              : Number(averageData).toLocaleString()
                            : '--'}
                          {card.units ? card.units : ''}
                        </DataValue>
                        <DataSpace />
                      </CardDataWithAverage>
                    </CardContent>
                  )}
                </Card>
              );
            })}
          </TopSectionSummaryMetricsContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <MiddleSectionSummeryMetrics>
              {cardRows.map((row, index) => {
                return (
                  <Droppable droppableId={row.id} key={row.id} direction="horizontal">
                    {(provided, snapshot) => (
                      <MiddleSectionSummeryMetricsRow {...provided.droppableProps} ref={provided.innerRef}>
                        {row.cards.map((card, index) => {
                          return (
                            <Draggable key={card.hash} draggableId={card.hash} index={index}>
                              {(provided, snapshot) => (
                                <div className="chart-card" ref={provided.innerRef} {...provided.draggableProps}>
                                  <card.component {...cardProps[card.id]} draggableProps={provided.dragHandleProps} />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </MiddleSectionSummeryMetricsRow>
                    )}
                  </Droppable>
                );
              })}
            </MiddleSectionSummeryMetrics>
          </DragDropContext>

          <PrintFooter>
            <ViasatLogoIcon viewBox="0 0 66 22" style={{width: 66, height: 22}} htmlColor={VIASAT_LOGO_ICON_COLOR} />
            Copyright &copy; {copyrightYear} Viasat,Inc. All right reserved
          </PrintFooter>
        </SummaryMetricsBody>
        {activeAircraftInfoData?.length > 1 ? (
          <SummaryMetricAircraftInfoListContainer browserType={getBrowserName()}>
            <SummaryMetricsAircraftDetails
              aircraftInfo={activeAircraftInfoData}
              isInternal={isInternal}
              isValueAddedReseller={isValueAddedReseller}
              isLoading={isActiveAircraftInfoLoading}
              datePickerState={datePickerState}
              allFilters={allFilters}
            />
          </SummaryMetricAircraftInfoListContainer>
        ) : null}
      </div>
    </PageContainerTemplate>
  );
};

export default SummaryMetricsPage;
