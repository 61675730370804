/**
 * Copyright (C) 2024 Viasat, Inc.
 * All rights reserved.
 * The information in this software is subject to change without notice and
 * should not be construed as a commitment by Viasat, Inc.
 *
 * Viasat Proprietary
 * The Proprietary Information provided herein is proprietary to Viasat and
 * must be protected from further distribution and use. Disclosure to others,
 * use or copying without express written authorization of Viasat, is strictly
 * prohibited.
 *
 * Description: Render a flight path
 */

import React, {useEffect, useState} from 'react';
import {OverlayView, Polyline} from '@react-google-maps/api';
import moment from 'moment';
import {BLUE, GREY} from '../common/theme/Colors';
import {MAP_ELID_SECTION, MAP_ELID_FLIGHTPATH_SUBSECTION} from '../../utils/constants';
import {
  DATE_TIME_LOCAL_FORMAT_MINUTES,
  formatMomentInputAsTimeWithSeconds,
  formatStartEndDurationValue
} from '../../utils/DateTimeUtils';
import {getElementIdFragmentBase, getElementIdFromFragmentBase} from '../../utils/ElementIdUtils';
import {PathPopup, HoverMarker} from './MapStyles';
import {
  getFlightPathSegmentsBasedOnAvailability,
  PolylineConfiguration,
  MapFlightPathProps,
  HoverPoint,
  constructPolylineInput,
  getFutureFlightPath
} from './MapFlightPathUtil';
import {MapViewContext} from '../../utils/MapUtil';

import {getTimelineHoverDate, getTimelineHoverInside} from '../flightDetails/useDetailHoverLine';
import {FlightPathBeamIncident} from '../../store/queries/flightDetails/flightPathBeamIncidents';
import AvailabilityDot from '../common/AvailabilityDot';

const SEGMENT_ELEMENT_ID_BASE = getElementIdFragmentBase(MAP_ELID_SECTION, MAP_ELID_FLIGHTPATH_SUBSECTION, 'segment');

/**
 * FC to render the connectivity status dot on the hover tooltip
 */
export const AvailabilityDotWithRoaming: React.FC<{color: string; hasHatch: boolean}> = ({color, hasHatch}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      style={{
        pointerEvents: 'none'
      }}
    >
      <pattern id={`${color}pattern`} x="0" y="0" width="4" height="4" patternUnits="userSpaceOnUse">
        <rect x="0" y="0" width="4" height="4" fill={color}></rect>
        {hasHatch && <path d="M1,0 l5,100" stroke="white" stroke-width="1"></path>}
      </pattern>
      <circle cx="50%" cy="50%" r={4} fill={`url(#${color}pattern)`} stroke={'#fff'} strokeWidth="0.7" />
    </svg>
  );
};

/**
 * FC to render the on hover fight path tooltip
 */
export const FlightPathPopup: React.FC<{
  availability: string;
  color: string;
  groupStart: string;
  groupEnd: string;
  idx: string;
  isRoaming: boolean;
  hasIncident: boolean;
  incidents: FlightPathBeamIncident;
}> = ({availability, color, groupStart, groupEnd, idx, isRoaming, hasIncident, incidents}) => {
  return (
    <PathPopup>
      <div className="time-row">
        <div id={getElementIdFromFragmentBase(SEGMENT_ELEMENT_ID_BASE, idx, 'hoverPopup-startTime-label')}>
          {formatMomentInputAsTimeWithSeconds(groupStart)}
        </div>
        &nbsp;-&nbsp;
        <div id={getElementIdFromFragmentBase(SEGMENT_ELEMENT_ID_BASE, idx, 'hoverPopup-endTime-label')}>
          {formatMomentInputAsTimeWithSeconds(groupEnd)}
        </div>
        <div
          className="duration"
          id={getElementIdFromFragmentBase(SEGMENT_ELEMENT_ID_BASE, idx, 'hoverPopup-duration-label')}
        >
          {'(' + formatStartEndDurationValue(groupStart, groupEnd) + ')'}
        </div>
      </div>
      <div className="availability-row">
        <div>
          <AvailabilityDotWithRoaming color={color} hasHatch={isRoaming} />
        </div>
        <div id={getElementIdFromFragmentBase(SEGMENT_ELEMENT_ID_BASE, idx, 'hoverPopup-availability-label')}>
          {availability ? availability : ''}
          {isRoaming && ': Roaming'}
        </div>
      </div>
      {hasIncident && (
        <div
          className="incidents-row"
          id={getElementIdFromFragmentBase(SEGMENT_ELEMENT_ID_BASE, idx, 'hoverPopup-incident-label')}
        >
          <AvailabilityDot sx={{color: GREY}} />
          <div>Network: {incidents.CAUSE}</div>
          <div className="incident-start-time">{formatMomentInputAsTimeWithSeconds(incidents.IMPACT_START)}</div>
        </div>
      )}
    </PathPopup>
  );
};

/**
 * FC to render the future flight path segments
 */
export const FutureFlightPath: React.FC<{pathData: PolylineConfiguration}> = ({pathData}) => {
  const futurePathSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    strokeWeight: 2,
    scale: 3,
    strokeColor: BLUE,
    zIndex: 5
  };

  return (
    <Polyline
      path={pathData.flightPath}
      options={{
        strokeOpacity: 0,
        strokeWeight: 0,
        icons: [
          {
            icon: futurePathSymbol,
            offset: '0%',
            repeat: '15px'
          }
        ]
      }}
    />
  );
};

/**
 * FC to render the roaming flight path segments
 */
export const RoamingFlightPath: React.FC<{pathData: PolylineConfiguration}> = ({pathData}) => {
  const roamingSymbol = {
    path: 'M0,4 l4,-4',
    strokeColor: 'white',
    strokeOpacity: 1,
    strokeWeight: 1.5,
    scale: 0.3,
    zIndex: 5
  };

  return (
    <Polyline
      path={pathData.flightPath}
      options={{
        strokeColor: pathData.color,
        icons: [
          {
            icon: roamingSymbol,
            offset: '0%',
            repeat: '5px'
          }
        ],
        strokeOpacity: 1
      }}
    />
  );
};

/**
 * FC to render the beam incident flight path segments
 */
export const BeamIncidentFligtPath: React.FC<{pathData: PolylineConfiguration}> = ({pathData}) => {
  const dottedLineSymbol = {
    path: 'M 0,1 0,1',
    strokeOpacity: 1,
    strokeColor: pathData.color,
    scale: 4
  };

  return (
    <Polyline
      path={pathData.flightPath}
      options={{
        strokeOpacity: 0,
        icons: [
          {
            icon: dottedLineSymbol,
            offset: '0',
            repeat: '8px'
          }
        ]
      }}
    />
  );
};

/**
 * FC to handle the flight path hover events
 */
export const FlightPathEvent: React.FC<{
  pathData: PolylineConfiguration;
  index: number;
  onMouseMove;
  hoverPoint;
}> = ({pathData, index, onMouseMove, hoverPoint}) => {
  return (
    <Polyline
      path={pathData.flightPath}
      options={{
        strokeOpacity: 0,
        strokeWeight: 25,
        zIndex: 5
      }}
      onMouseMove={(e) => {
        onMouseMove(
          e,
          pathData.color,
          pathData.startTimestamp,
          pathData.endTimestamp,
          pathData.availability,
          index.toString(),
          pathData.isRoaming,
          pathData.hasIncident,
          pathData.incidents
        );
      }}
      onMouseOut={() => {
        hoverPoint(null);
        sessionStorage.setItem('mapPathHover', 'false');
      }}
    />
  );
};

/**
 * FC to render the entire polyline flight path segments
 */
export const PolylineMapPath: React.FC<{
  pathData: PolylineConfiguration;
  index: number;
  mapView;
  pathDataLength;
  onMouseMove;
  hoverPoint;
}> = ({pathData, index, mapView, pathDataLength, onMouseMove, hoverPoint}) => {
  let polylineData = <></>;
  if (pathData.isFuture) {
    polylineData = <FutureFlightPath pathData={pathData} />;
  } else {
    if (pathData.isRoaming) {
      polylineData = (
        <>
          <RoamingFlightPath pathData={pathData} />
          <FlightPathEvent pathData={pathData} index={index} onMouseMove={onMouseMove} hoverPoint={hoverPoint} />
        </>
      );
    } else {
      if (index === pathDataLength - 1 && mapView === MapViewContext.CONNECTIVITY_OUTLOOK_MAP) {
        polylineData = (
          <>
            <Polyline
              path={pathData.flightPath}
              options={{
                strokeColor: pathData.color,
                strokeWeight: 4,
                strokeOpacity: 1,
                geodesic: true,
                zIndex: 5,
                icons: [
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      fillColor: pathData.color,
                      scale: 4,
                      strokeWeight: 5
                    },
                    offset: '100%'
                  }
                ]
              }}
            />
            <FlightPathEvent pathData={pathData} index={index} onMouseMove={onMouseMove} hoverPoint={hoverPoint} />
          </>
        );
      } else {
        if (pathData.hasIncident) {
          polylineData = (
            <div
              data-has-incident={pathData.hasIncident}
              onClick={() =>
                onMouseMove(
                  {
                    latLng: new google.maps.LatLng(pathData.flightPath[0].lat, pathData.flightPath[0].lng)
                  } as google.maps.MapMouseEvent,
                  pathData.color,
                  pathData.startTimestamp,
                  pathData.endTimestamp,
                  pathData.availability,
                  index.toString(),
                  pathData.isRoaming,
                  pathData.hasIncident,
                  pathData.incidents
                )
              }
              className="incident-path"
              style={{width: '100px', height: '100px'}}
            >
              <BeamIncidentFligtPath pathData={pathData} />
              <FlightPathEvent pathData={pathData} index={index} onMouseMove={onMouseMove} hoverPoint={hoverPoint} />
            </div>
          );
        } else {
          polylineData = (
            <>
              <Polyline
                path={pathData.flightPath}
                options={{
                  strokeColor: pathData.color,
                  strokeWeight: 4,
                  strokeOpacity: 1,
                  geodesic: true,
                  zIndex: 5
                }}
              />
              <FlightPathEvent pathData={pathData} index={index} onMouseMove={onMouseMove} hoverPoint={hoverPoint} />
            </>
          );
        }
      }
    }
  }
  return polylineData;
};

/**
 * Parent FC to render all the consolidated flight paths
 */
const MapFlightPath: React.FC<MapFlightPathProps> = (props: MapFlightPathProps) => {
  const [hoverPoint, setHoverPoint] = useState<HoverPoint>();
  const [hoveredPointIdx, setHoveredPointIdx] = useState<number>();
  const [hoveredSegmentIdx, setHoveredSegmentIdx] = useState<number>();
  const flightPathSegments = getFlightPathSegmentsBasedOnAvailability(props.flightPathData);
  const polylinePathData = constructPolylineInput(flightPathSegments, props.mapViewContext);
  if (props.isLiveFlight) {
    const futureFlight = getFutureFlightPath(props.flightPathData);
    if (futureFlight) polylinePathData.push(futureFlight);
  }

  const handleHoverLine = (event: any) => {
    const curr = props.hoverLineContainerRef ? props.hoverLineContainerRef.current : null;
    const topCurr = curr ? curr.parentElement.parentElement : null;
    const {scrollLeft} = topCurr ? topCurr : ({} as any);
    const {offsetTop, scrollTop} = curr ? curr : ({} as any);
    const chartStart =
      props.chartTimeSettings && props.chartTimeSettings.start ? props.chartTimeSettings.start.toString() : '';

    const hoverDate = getTimelineHoverDate(
      event.clientX + scrollLeft,
      props.parentLeftOffset,
      chartStart,
      props.liveFlightOffset ? props.liveFlightOffset.startOffSet : 0,
      props.liveFlightOffset ? props.liveFlightOffset.unit : 0
    );

    const formattedHoverStr = moment.utc(hoverDate).format(DATE_TIME_LOCAL_FORMAT_MINUTES);

    const isHoveredInsideChart = moment(formattedHoverStr).isBetween(
      moment(props.chartTimeSettings.start),
      moment(props.chartTimeSettings.end)
    );
    const inside = getTimelineHoverInside(event.clientY, offsetTop, scrollTop, props.isMinimized);

    if (inside && isHoveredInsideChart) {
      const hoveredSegment = polylinePathData.findIndex((pathData) =>
        moment(formattedHoverStr).isBetween(moment(pathData.startTimestamp), moment(pathData.endTimestamp))
      );
      const hoveredPathPoint = props.flightPathData.flightPath.findIndex(
        (path) => path.timestamp === formattedHoverStr
      );
      setHoveredPointIdx(hoveredPathPoint);
      setHoveredSegmentIdx(hoveredSegment);
    } else {
      setHoveredPointIdx(-1);
      setHoveredSegmentIdx(-1);
    }
  };

  if (props.hoverLineContainerRef && !props.isFullScreen) {
    window.addEventListener('mousemove', handleHoverLine);
  } else {
    window.removeEventListener('mousemove', handleHoverLine);
  }

  const handlePolylineMouseMove = (
    e: google.maps.MapMouseEvent,
    color: string,
    startTimestamp: string,
    endTimestamp: string,
    availability: string,
    idx: string,
    isRoaming: boolean,
    hasIncident: boolean,
    incidents?: FlightPathBeamIncident
  ) => {
    const position = e.latLng.toJSON();
    setHoverPoint({
      position,
      color,
      startTimestamp,
      endTimestamp,
      availability,
      idx,
      isRoaming,
      hasIncident,
      incidents
    });
    sessionStorage.setItem('mapPathHover', 'true');
  };

  useEffect(() => {
    if (hoveredPointIdx !== -1) {
      const hoveredPoint = props.flightPathData.flightPath[hoveredPointIdx];
      const hoveredSegement = polylinePathData[hoveredSegmentIdx];

      if (hoveredPoint && hoveredSegement) {
        setHoverPoint({
          position: {lat: hoveredPoint.lat, lng: hoveredPoint.lng},
          color: hoveredSegement.color,
          startTimestamp: hoveredSegement.startTimestamp,
          endTimestamp: hoveredSegement.endTimestamp,
          availability: hoveredSegement.availability,
          idx: hoveredSegmentIdx.toString(),
          isRoaming: hoveredSegement.isRoaming,
          hasIncident: hoverPoint?.hasIncident,
          incidents: hoveredSegement.incidents
        });
      } else {
        setHoverPoint(null);
      }
    }
    //eslint-disable-next-line
  }, [hoveredPointIdx, hoveredSegmentIdx]);
  return (
    <div>
      {polylinePathData.map((pathData, index) => {
        return (
          <>
            <PolylineMapPath
              pathData={pathData}
              index={index}
              mapView={props.mapViewContext}
              pathDataLength={polylinePathData.length}
              onMouseMove={handlePolylineMouseMove}
              hoverPoint={setHoverPoint}
            />
            {/* On Hover Tooltip */}
            {hoverPoint && (
              <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={hoverPoint.position}>
                <>
                  <FlightPathPopup
                    availability={hoverPoint.availability}
                    color={hoverPoint.color}
                    groupStart={hoverPoint.startTimestamp}
                    groupEnd={hoverPoint.endTimestamp}
                    idx={hoverPoint.idx}
                    isRoaming={hoverPoint.isRoaming}
                    hasIncident={hoverPoint.hasIncident}
                    incidents={hoverPoint.incidents}
                  />
                  {/* The color dot on the tooltip indicating the status */}
                  <HoverMarker>
                    <AvailabilityDotWithRoaming color={hoverPoint.color} hasHatch={hoverPoint.isRoaming} />
                  </HoverMarker>
                </>
              </OverlayView>
            )}
          </>
        );
      })}
    </div>
  );
};

export default MapFlightPath;
